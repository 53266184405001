<template>
  <div>
    <van-dialog v-bind:value="closePop" title="温馨提示" :show-confirm-button="false">
      <van-form class="from" style="margin: 16px 10px" input-align="left">
        <div class="dialogContent">该项目已关闭,详情请查看平台的项目公告</div>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="confirm">确认</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getList } from "@/api/api";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['closePop']),
  },
  created() {
    console.log('closePop',this.closePop)
  },
  methods: {
    ...mapMutations(['setClosePop']),
    confirm() {
      this.setClosePop(false);
      if(location.pathname!="/me") this.$router.replace({ path: "/me" })
    },
  },
};
</script>
<style scoped>
.dialogContent {
  margin: 15px;
}
</style>