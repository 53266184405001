<template>
  <div>
    <van-dialog v-model="showDialog"  title="温馨提示" :show-confirm-button="false">
      <van-form class="from" style="margin: 16px 10px" input-align="left">
        <div class="dialogContent">已暂停电子档案申请，如有疑问请联系项目办。</div>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="confirm">确认</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getList } from "@/api/api";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      showDialog:true,
    };
  },
  computed: {
    ...mapState(['frozenPop']),
  },
  created() {
    console.log('frozenPop',this.frozenPop)
  },
  methods: {
    ...mapMutations(['clearFrozen']),
    confirm() {
      this.clearFrozen();
      console.log("location.pathname",location.pathname)
      if(location.pathname!="/login") this.$router.replace({ path: "/login" })
    },
  },
};
</script>
<style scoped>
.dialogContent {
  margin: 15px;
}
</style>